interface CardSelectionScreenTranslation {
  heading: string,
  expiredLabel: string;
  confirmButton: string
}

const en: CardSelectionScreenTranslation = {
  heading: `Select Card`,
  expiredLabel: 'Expired',
  confirmButton: `CONFIRM`
}

const ja: CardSelectionScreenTranslation = {
  heading: `カードを選択`,
  expiredLabel: '有効期限切れ',
  confirmButton: `保存`
}

export const Translations = {
  1: en,
  2: ja
}
