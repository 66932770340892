import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Translations} from "../../../../translations/cart-details/unauthenticated-cart-editable-row.translations";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {selectedLanguage} from "../../../../shared/core/shared.selectors";
import * as fromRoot from "../../../../../state/app.state";
import {QuantityDecrementRequest, QuantityIncrementRequest, RemoveItemRequest} from "../../../core/cart.actions";
import {environment} from "../../../../../../environments/environment";

@Component({
  selector: 'app-authenticated-cart-editable-row',
  templateUrl: './authenticated-cart-editable-row.component.html',
  styleUrls: ['./authenticated-cart-editable-row.component.css']
})
export class AuthenticatedCartEditableRowComponent implements OnInit, OnDestroy {
  language: number = 1;
  @Input() data: any;
  @Input() currencySymbol: string;
  translations: any = Translations;
  cdnUrl = environment.cdnURL;
  images: any;

  // Set up language specific translations
  languageSubscription: Subscription = this.store.pipe(select(selectedLanguage)).subscribe(selectedLanguage => {
    this.language = selectedLanguage;
  });

  constructor(
    private store: Store<fromRoot.State>,
  ) {
    this.translations = Translations;
  }

  ngOnInit(): void {
    this.images = this.data?.cdnImages?.map((imageUrl) => {
      if (imageUrl) {
        return this.cdnUrl + '/' + imageUrl;
      }
    });
  }

  increaseQuantity(itemId) {
    this.store.dispatch(QuantityIncrementRequest({payload: {itemId}}));

  }

  decreaseQuantity(itemId) {
    this.store.dispatch(QuantityDecrementRequest({payload: {itemId}}));
  }

  removeItem(itemId) {
    this.store.dispatch(RemoveItemRequest({payload: {itemId}}));
  }

  ngOnDestroy() {
    this.languageSubscription?.unsubscribe();
  }

}
