interface AuthenticatedCartScreenTranslations {
  noData: string,
  left: {
    heading: string,
    discountCoupon: string,
    applyDiscountCouponButton: string,
    itemsSubtotal: string,
    taxes: string,
    shipping: string,
    total: string
  },
  right: {
    heading: string,
    name: string,
    emailAddress: string,
    shippingAddress: string,
    paymentDetails: string,
    expiredLabel: string,
    changeButton: string,
    purchaseDisabled: string,
    addAddress: string,
    selectAddress: string,
    addCard: string,
    selectCard: string,
    placeOrder: string,
    productDeliveryPolicyPart1: string,
    productDeliveryPolicyPart2: string,
    productDeliveryPolicyPart3: string,
  },
  goToCatalogPage: string,
}

const en: AuthenticatedCartScreenTranslations = {
  noData: `Your Cart is empty! \n Select something from our Selections`,
  left: {
    heading: `My Cart`,
    discountCoupon: `Discount Coupon`,
    applyDiscountCouponButton: `APPLY DISCOUNT COUPON`,
    itemsSubtotal: `Items SubTotal`,
    taxes: `Taxes`,
    shipping: `Shipping`,
    total: `Total Amount`
  },
  right: {
    heading: `Purchase Details`,
    name: `Name`,
    emailAddress: `Email Address`,
    shippingAddress: `Shipping Address`,
    paymentDetails: `Payment Details`,
    expiredLabel: 'Expired',
    changeButton: `CHANGE`,
    purchaseDisabled: `Purchase option is currently disabled.`,
    addAddress: `ADD ADDRESS`,
    selectAddress: `SELECT ADDRESS`,
    addCard: `ADD CARD`,
    selectCard: `SELECT CARD`,
    placeOrder: `PLACE ORDER`,
    productDeliveryPolicyPart1:
      `※One-time purchase teas are shipped within 3 business days. First package of the tea subscription is shipped out within 3 business days. We cannot accept returns after shipping the package unless the product is defective. For cancelation and return, please reach out to order.japan@load-road.com. Please refer to`,
    productDeliveryPolicyPart2: `this document`,
    productDeliveryPolicyPart3: `for details of the subscription contract and cancellation.`
  },
  goToCatalogPage: 'Go to Shop Page',
}

const ja: AuthenticatedCartScreenTranslations = {
  noData: `カートが空です`,
  left: {
    heading: `カート`,
    discountCoupon: `クーポン`,
    applyDiscountCouponButton: `クーポンを追加`,
    itemsSubtotal: `小計`,
    taxes: `消費税`,
    shipping: `送料`,
    total: `合計`
  },
  right: {
    heading: `送付先情報の確認`,
    name: `お名前`,
    emailAddress: `メールアドレス`,
    shippingAddress: `お届け先`,
    paymentDetails: `お支払い情報の確認`,
    expiredLabel: '有効期限切れ',
    changeButton: `変更する`,
    purchaseDisabled: `このオプションは現在無効です`,
    addAddress: `住所を追加`,
    selectAddress: `住所を選択`,
    addCard: `カードを追加`,
    selectCard: `カードを選択`,
    placeOrder: `注文する`,
    productDeliveryPolicyPart1: `※単品購入分の発送は、決済確認後3営業日以内といたします。サブスクリプションの場合、初回発送は決済確認後3営業日以内とし、2回目以降の発送は更新日から3営業日以内といたします。
商品発送後のお客様都合での返品・返却等はお受けいたしかねます。商品に不良があった場合は良品と交換いたしますので、<span class="ff-lang-ja-text-en">order.japan@load-road.com</span>までご連絡ください。
サブスクリプションの契約内容及び解約方法については`,
    productDeliveryPolicyPart2: `こちら`,
    productDeliveryPolicyPart3: `を参照ください。`
  },
  goToCatalogPage: 'ホームに戻る',
}

export const Translations = {
  1: en,
  2: ja
}
