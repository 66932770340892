import {password} from "../../../shared/constants/validation.constants";

interface SignupFormTranslation {
  heading: string,
  fullNameLabel: string,
  emailLabel: string,
  passwordLabel: string,
  confirmPasswordLabel: string,
  createAccountButton: string,
  loginButton: string,
  errorMessageMap: object
}

const en: SignupFormTranslation = {
  heading: `Create Teplo Account`,
  fullNameLabel: `Full Name`,
  emailLabel: `Email`,
  passwordLabel: `Password`,
  confirmPasswordLabel: `Confirm Password`,
  createAccountButton: `Create Account`,
  loginButton: `Login`,
  errorMessageMap: {
    fullName: {
      required: `This field is required.`,
      pattern: `Full name should be alphabetical only.`
    },
    email: {
      required: `This field is required.`,
      pattern: `Invalid Email Id.`
    },
    password: {
      required: `This field is required.`,
      minlength: `Password must contain at least ${password.length.min} characters`,
      maxlength: `Password must contain only ${password.length.max} characters`,
      lowerCaseCheckFailed: `Password must contain at least 1 Lowercase character`,
      upperCaseCheckFailed: `Password must contain at least 1 Uppercase character`,
      numberCheckFailed: `Password must contain at least 1 numeric character`,
      specialCharCheckFailed: `Password must contain a Special character`,
      hint: `Password should be in English A-Z. Password needs at-least 1 digit, 1 special character, 1 uppercase & 1 lower case letter. It should be at-least ${password.length.min} characters long.`
    },
    confirmPassword: {
      required: `This field is required.`,
      passwordMatchFailed: `Passwords do not match.`
    }
  }
}

const ja: SignupFormTranslation = {
  heading: `アカウントをつくる Step1`,
  fullNameLabel: `お名前`,
  emailLabel: `メールアドレス`,
  passwordLabel: `パスワード`,
  confirmPasswordLabel: `パスワードを再入力`,
  createAccountButton: `次へ`,
  loginButton: `ログイン`,
  errorMessageMap: {
    fullName: {
      required: `入力が必須です`,
      pattern: `名前は日本語かアルファベットで入力してください`
    },
    email: {
      required: `入力が必須です`,
      pattern: `Eメールが無効です`
    },
    password: {
      required: `入力が必須な項目です`,
      minlength: `パスワードは半角で${password.length.min}文字以上で設定してください`,
      maxlength: `パスワードは半角で${password.length.max}文字以内で設定してください`,
      lowerCaseCheckFailed: `パスワードは1文字以上の半角小文字の英語を入れてください`,
      upperCaseCheckFailed: `パスワードは1文字以上の半角大文字の英語を入れてください`,
      numberCheckFailed: `パスワードは1文字以上の半角数字を入れてください`,
      specialCharCheckFailed: `パスワードは1文字以上の半角特殊文字(&や@等)を入れてください`,
      hint: `パスワードは半角で、少なくとも1つの数字、1つの大文字、1つの小文字、1つの特殊文字を使用して、${password.length.min}文字以上で入力してください`
    },
    confirmPassword: {
      required: `入力が必須です`,
      passwordMatchFailed: `パスワードが一致していません`
    }
  }
}

export const Translations = {
  1: en,
  2: ja
}
