import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';
import {Action, select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';

import * as AuthenticationActions from './authentication.actions';
import {SetForgotPasswordStatus} from './authentication.actions';
import {AuthenticationService} from './authentication.service';
import * as fromRoot from '../../../state/app.state';
import {CommonService} from '../../shared/services/common.service';
import {LoginRequestModel} from "../../models/login-request.model";
import {ProfileModel} from "../../models/profile.model";
import {SetIsMobileSidenavOpen} from "../../shared/core/shared.actions";
import {UpdateProfileFormComponent} from "../forms/update-profile-form/update-profile-form.component";
import {LazyLoaderService} from "../../shared/services/lazy-loader.service";
import {CartSetRequest, FetchCartCountRequest, FetchCartRequest} from "../../cart-details/core/cart.actions";
import {guestCart} from "../../cart-details/core/cart.selectors";


@Injectable()
export class AuthenticationEffects {
  login$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.LoginRequest),
      map((action: any) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload: LoginRequestModel) =>
        this.authenticationService.login(payload).pipe(
          map((response: any) => {
            const {data, message} = response;
            const token: string = data.token;
            const profile: ProfileModel = data.profile;
            this.commonService.setAuthenticationToken(token);
            return AuthenticationActions.LoginSuccess({profile, message});
          }),
          catchError((error) => {
            return of(AuthenticationActions.LoginFailure());
          }),
          tap((action: any) => {
            if (action.type === AuthenticationActions.LoginSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
              this.commonService.notification(action.message, 'success');
              const currentRoute = this.router.url;
              if (currentRoute === '/cart') {
                this.commonService.triggerAuthenticationStatusCheck.next();
                this.store.pipe(select(guestCart))
                  .subscribe((data) => {
                    if (data?.items?.length) {
                      this.store.dispatch(CartSetRequest({payload: data}))
                    }
                  }).unsubscribe();
              }
              this.store.dispatch(FetchCartCountRequest({}));
              this.commonService.notification(
                action.message,
                'success'
              );
            } else if (action.type === AuthenticationActions.LoginFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.triggerAuthenticationStatusCheck.next();
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  createAccount$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.CreateAccountRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.createAccount(payload).pipe(
          map((response: any) => {
            const {data} = response;
            const token = data.token;
            this.commonService.setAuthenticationToken(token);
            const userProfile: ProfileModel = data.profile;
            return AuthenticationActions.CreateAccountSuccess({
              profile: userProfile,
              message: response.message,
            });
          }),
          catchError((error) => {
            return of(AuthenticationActions.CreateAccountFailure());
          }),
          tap((action) => {
            if (action.type === AuthenticationActions.CreateAccountSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
              const currentRoute = this.router.url;
              if (currentRoute === '/cart') {
                this.commonService.triggerAuthenticationStatusCheck.next();
                this.store.pipe(select(guestCart))
                  .subscribe((data) => {
                    if (data?.items?.length) {
                      this.store.dispatch(CartSetRequest({payload: data}))
                    }
                  }).unsubscribe();

                this.store.dispatch(FetchCartRequest());
              } else {
                this.commonService.openDialog({
                  data: {component: UpdateProfileFormComponent},
                });
              }
              this.store.dispatch(FetchCartCountRequest({}));
              this.commonService.notification(
                action.message,
                'success'
              );
            } else if (action.type === AuthenticationActions.CreateAccountFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  logout$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.LogoutRequest),
      map((action) => {
        return action;
      }),
      mergeMap(() =>
        this.authenticationService.logout().pipe(
          map((response: any) => {
            return AuthenticationActions.LogoutSuccess({message: response.message});
          }),
          catchError((error) => {
            return of(AuthenticationActions.LogoutFailure(error.message));
          }),
          tap((action) => {
            if (action.type === AuthenticationActions.LogoutSuccess.type) {
              this.commonService.closeDialog();
              // Code to execute on API Success Action dispatch
              this.router.navigate(['/']).then(() => {
                this.commonService.clearAppState();
                this.commonService.clearStorage();
              });
              this.commonService.notification(
                action.message,
                'success'
              );
            } else if (action.type === AuthenticationActions.LogoutFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.store.dispatch(SetIsMobileSidenavOpen({isMobileSidenavOpen: false}));
          })
        )
      )
    )
  );

  resetPasswordRequest$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.ForgotPasswordRequest),
      map((action) => {
        this.commonService.startLoading();
        return action.payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.resetPasswordRequest(payload).pipe(
          map((response: any) => {
            return AuthenticationActions.ForgotPasswordSuccess({message: response.message});
          }),
          catchError((error) => {
            return of(AuthenticationActions.ForgotPasswordFailure());
          }),
          tap((action) => {
            if (action.type === AuthenticationActions.ForgotPasswordSuccess.type) {
              // Code to execute on API Success Action dispatch
              const isForgotPasswordSuccessful = {
                isForgotPasswordSuccessful: true,
                emailId: payload.email
              }
              this.store.dispatch(SetForgotPasswordStatus(isForgotPasswordSuccessful));
              this.commonService.notification(
                action.message,
                'success'
              );
            } else if (action.type === AuthenticationActions.ForgotPasswordFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
            this.commonService.stopLoading();
          })
        )
      )
    )
  );

  updateAccount$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.UpdateAccountRequest),
      map((action) => {
        return action.payload;
      }),
      mergeMap((payload) =>
        this.authenticationService.updateAccount(payload).pipe(
          map((response: any) => {
            const {data} = response;
            const userProfile: ProfileModel = data;
            return AuthenticationActions.UpdateAccountSuccess({
              profile: userProfile,
              message: response.message,
            });
          }),
          catchError((error) => {
            return of(AuthenticationActions.UpdateAccountFailure(error.message));
          }),
          tap((action) => {
            if (action.type === AuthenticationActions.UpdateAccountSuccess.type) {
              // Code to execute on API Success Action dispatch
              this.commonService.closeDialog();
              this.commonService.notification(
                action.message,
                'success'
              );
            } else if (action.type === AuthenticationActions.UpdateAccountFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );


  fetchProfile$: Observable<Action> = createEffect(() =>
    this.actions.pipe(
      ofType(AuthenticationActions.FetchProfileRequest),
      map((action) => {
        return action;
      }),
      mergeMap(() =>
        this.authenticationService.fetchProfile().pipe(
          map((response: any) => {
            const {data} = response;
            const profileInfo: ProfileModel = data;
            return AuthenticationActions.FetchProfileSuccess({
              profile: profileInfo,
              message: response.message,
            });
          }),
          catchError((error) => {
            return of(AuthenticationActions.FetchProfileFailure(error.message));
          }),
          tap((action) => {
            if (action.type === AuthenticationActions.FetchProfileSuccess.type) {
              // Code to execute on API Success Action dispatch
            } else if (action.type === AuthenticationActions.FetchProfileFailure.type) {
              // Code to execute on API Failure Action dispatch
            }
          })
        )
      )
    )
  );

  constructor(
    private store: Store<fromRoot.State>,
    private authenticationService: AuthenticationService,
    private actions: Actions,
    private router: Router,
    private commonService: CommonService,
    private lazyLoaderService: LazyLoaderService
  ) {
  }
}
